.container {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  margin-bottom: 16px;
  overflow: hidden;
}

.container.grab {
  cursor: grab;
}

.container.grabbing {
  cursor: grabbing;
  user-select: none;
}

.chevron {
  cursor: pointer;
  min-height: 14px;
  min-width: 14px;
}

.innerContainer {
  display: flex;
  gap: 8px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.innerContainer.overflowStart::before,
.innerContainer.overflowEnd::after {
  bottom: 0;
  content: "";
  height: 2rem;
  pointer-events: none;
  position: absolute;
  width: 2.5rem;
  z-index: 1;
}

.innerContainer.overflowEnd::after {
  right: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
}

.innerContainer.overflowStart::before {
  left: 0;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
}
